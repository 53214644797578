import styled from 'styled-components'

import colors from '../../../../../domain/Colors'

export const Ripple = styled.span`
  background-color: rgba(255, 255, 255, 0);
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  opacity: 0;
  position: absolute;
  transition: background-color 0.3s;
  user-select: none;
  width: 30px;
`

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.05rem;
  gap: 1rem;
  margin-bottom: 1rem;
  user-select: none;

  &:hover {
    cursor: pointer;
    ${Ripple} {
      background-color: ${({ theme }) => theme.colors.primary};
      opacity: 0.25;
    }
  }
`

export const InputContainer = styled.span`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  position: relative;
  justify-content: center;
  height: 30px;
  width: 30px;

  input {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  svg {
    height: 24px;
    width: 24px;
    z-index: 2;

    &.inputIcon path {
      fill: ${colors.labelGray};
    }

    &.inputIconChecked path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`
