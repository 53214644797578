import React, { createContext, FunctionComponent } from 'react'

import { Family } from '../../domain/family/Family'
import { useFamilyQuery } from '../../queries/family/queries'

interface ContextValue {
  isLoadingFamily: boolean
  isErrorFamily: boolean
  familyId: number
  family: Family | undefined
  familyError: Error | null
}

interface Props {
  familyId: number
  children?: React.ReactNode
}

const Context = createContext<ContextValue | undefined>(undefined)

const Provider: FunctionComponent<Props> = ({ children, familyId }) => {
  const { isLoadingFamily, isErrorFamily, family, familyError } = useFamilyQuery(familyId)
  return (
    <Context.Provider
      value={{
        isLoadingFamily,
        isErrorFamily,
        family,
        familyId,
        familyError
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context as CurrentFamilyContext,Provider as CurrentFamilyProvider }

export type { ContextValue as CurrentFamilyContextValue,Props as CurrentFamilyProviderProps }
