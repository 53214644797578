import { format } from 'date-fns'
import { MRT_ColumnDef } from 'material-react-table'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { formatMonetaryValue } from '../../../domain/formatter'
import { SimplifiedLancamento } from '../../../domain/lancamento/lancamento'
import { TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { useGetFilteredCategories } from '../../../queries/categoria/queries'
import { ValueCell } from '../Cells'
import { BudgetCellText } from '../Cells/BudgetCell'
import { CategoriaCellMemoized } from '../Cells/CategoriaCell'
import { PaymentMethodCell } from '../Cells/PaymentMethodCell'

const CenteredText = styled.span`
  text-align: center;
`

export interface LancamentoEdit {
  [lancamentoId: number]: {
    orcamentoId: number
  }
}

export const useColumns = (
  selectedRows: SimplifiedLancamento[],
  onCategorization: () => void,
  onSelectOrcamento?: () => void
) => {
  const columns = useMemo<MRT_ColumnDef<SimplifiedLancamento>[]>(
    () => [
      {
        header: 'Data',
        id: 'Data',
        accessorKey: 'data',
        Cell: ({ cell }) => {
          const value = cell.getValue()
          return value ? format(new Date(value as string), 'dd/MM/yyyy') : ''
        }
      },
      {
        header: 'Descrição',
        id: 'Descrição',
        accessorKey: 'descricao',
        Cell: ({ cell }) => {
          const value = cell.getValue() as SimplifiedLancamento['descricao']
          return <CenteredText>{value}</CenteredText>
        }
      },
      {
        header: 'Valor',
        id: 'Valor',
        accessorFn: (row) => (row.parcelado ? row.valorParcela : row.valor),
        Cell: ({
          cell,
          row: {
            original: { tipo, categoriaId }
          }
        }) => {
          const valor = cell.getValue() as SimplifiedLancamento['valor']
          const { category } = useGetFilteredCategories({ categoriaId })
          let type = tipo
          if (category?.nome === 'Resgates') {
            type = 4
          }
          return <ValueCell tipo={type}>{formatMonetaryValue(valor ?? 0)}</ValueCell>
        }
      },
      {
        header: 'Meio de Pagamento',
        id: 'Meio de Pagamento',
        accessorKey: 'meioPagamentoId',
        Cell: ({ cell, row }) => {
          const value = cell.getValue() as SimplifiedLancamento['meioPagamentoId']
          return (
            <PaymentMethodCell
              meioPagamentoId={value}
              row={row.original as unknown as SimplifiedLancamento}
            />
          )
        }
      },
      {
        header: 'Orçamento',
        id: 'Orçamento',
        accessorKey: 'orcamentoId',
        Cell: ({
          cell,
          row: {
            original: { id: lancamentoId, orcamentoPadrao, tipo }
          }
        }) => {
          const id = cell.getValue() as SimplifiedLancamento['orcamentoId']
          return (
            <BudgetCellText
              id={id}
              lancamentoId={lancamentoId}
              tipo={tipo}
              onSelectOrcamento={onSelectOrcamento}
              orcamentoPadrao={orcamentoPadrao}
            />
          )
        }
      },
      {
        header: 'Categoria',
        id: 'Categoria',
        accessorKey: 'categoriaId',
        Cell: ({
          cell,
          row: {
            original: { categoriaPadrao, id: lancamentoId, tipo, orcamentoId }
          }
        }) => {
          const id = cell.getValue()
          return (
            <CategoriaCellMemoized
              id={id}
              categoriaPadrao={categoriaPadrao}
              onCategorization={onCategorization}
              selectedRows={selectedRows}
              lancamentoId={lancamentoId}
              orcamentoId={orcamentoId}
              tipo={tipo}
            />
          )
        }
      },
      {
        header: 'Parcelado',
        id: 'Parcelado',
        accessorFn: ({ parcelado }) => parcelado,
        Cell: ({ cell }) => <>{cell.renderValue() ? 'Sim' : 'Não'}</>
      },
      {
        header: 'Tipo',
        id: 'tipo',
        accessorFn: ({ tipo }) => tipo,
        Cell: ({ cell }) => {
          const value = cell.getValue() as SimplifiedLancamento['tipo']
          return <ValueCell tipo={value}>{TipoOrcamento[value]}</ValueCell>
        }
      }
    ],
    [onCategorization, onSelectOrcamento, selectedRows]
  )

  return columns
}
