import { IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import { Navbar } from 'reactstrap'
import styled, { css } from 'styled-components'

import { MUI_APP_BAR_HEIGHT } from '../Navbars/AppBar/styles'

export const RightBarWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 997;
`

export const RightBarWidth = 350

export const StyledNavbar = styled(Navbar)`
  && {
    background: rgb(247, 250, 255);
    background: -moz-linear-gradient(90deg, rgba(247, 250, 255, 1) 0%, rgba(244, 247, 252, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(247, 250, 255, 1) 0%, rgba(244, 247, 252, 1) 100%);
    background: linear-gradient(90deg, rgba(247, 250, 255, 1) 0%, rgba(244, 247, 252, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7faff",endColorstr="#f4f7fc",GradientType=1);
    box-shadow: rgba(136, 152, 170, 0.15) -3px 0px 9px 0px;
    border-left: 1px solid #eff2f8;
    display: none;
    max-width: ${RightBarWidth}px;
    overflow-x: hidden;
    top: ${MUI_APP_BAR_HEIGHT}px;
    width: 100%;
    z-index: 999;

    @media screen and (min-width: 1200px) {
      display: block;
    }

    &.closed {
      right: -${RightBarWidth - 16}px;
    }
  }
`

export const NavBarContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
`

export const ToggleRightBarButton = styled(IconButton)<{ $isOpen: boolean }>`
  && {
    display: none;
    background: linear-gradient(90deg, #f7faff 50%, #f5f8ff 50%);
    border: 1px solid #eff2f8;
    border-radius: 40%;
    height: 50px;
    position: fixed;
    right: 1px;
    top: 50vh;
    transition: right 0.3s linear;

    @media screen and (min-width: 1200px) {
      display: block;
    }

    svg {
      fill: ${({ theme }) => theme.colors.primary};
      transform: rotate(0deg) translateX(-6px);
    }

    ${({ $isOpen }) =>
      $isOpen &&
      css`
        right: ${RightBarWidth - 15}px;

        svg {
          transform: rotate(-180deg) translateX(4px);
        }
      `}
  }
`

export const PlusIcon = styled(Link)`
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 25px;
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 26px;
  justify-content: center;
  transform: translate(0);
  transition: transform 0.2s ease-in-out;
  width: 26px;
  z-index: 1;

  &.right-position {
    transform: translateX(25px);
  }

  &:hover,
  &:focus {
    color: #fff;
    transform: translateY(10px);

    &.right-position {
      transform: translateX(25px) translateY(-1px);
    }
  }
`
