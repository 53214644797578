import React, { useMemo, useState } from 'react'

import { SimplifiedOrcamentoWithCategory, TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { useCurrentFamilyId } from '../../../hooks'
import { useFamilyOrcamentosForTable } from '../../../queries/orcamento/queries'
import { FieldSelect } from '../FieldSelect'

interface Props {
  defaultPlaceholder?: string
  label?: string
  onSelect: (newOrcamento: SimplifiedOrcamentoWithCategory) => void
  selectedId?: number
  type?: TipoOrcamento
  defaultValue?: SimplifiedOrcamentoWithCategory
}

export const FieldSelectOrcamento = ({
  selectedId,
  label = 'Orçamento *',
  type,
  defaultPlaceholder,
  defaultValue,
  onSelect
}: Props) => {
  const [filter, setFilter] = useState('')
  const { familyId } = useCurrentFamilyId()
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId)

  const placeholder =
    orcamentos?.find(({ id }) => id === selectedId)?.nome || defaultPlaceholder || 'Selecione...'

  const options =
    orcamentos
      ?.map((o) => ({
        value: o,
        label: o.nome
      }))
      .filter(({ label }) => label?.toLowerCase().includes(filter?.toLowerCase()))
      .filter(({ value: { tipo } }) => type === undefined || tipo === type) ?? []

  const selected = useMemo(
    () => !!defaultValue && options.find(({ value }) => value.id === defaultValue.id),
    [defaultValue, options]
  )

  // console.log({ selected })

  return (
    <FieldSelect
      label={label}
      filter={filter}
      onFilter={setFilter}
      // @ts-expect-error expected-error
      options={options?.sort((a, b) => a.label.localeCompare(b.label)) ?? []}
      // @ts-expect-error expected-error
      selectedValue={!!selected && selected?.value}
      loading={isLoadingOrcamentos}
      placeholder={placeholder}
      onChange={(novoOrcamento) => {
        setFilter('')
        // @ts-expect-error expected-error
        onSelect(novoOrcamento)
      }}
    />
  )
}
