// import { format, parseJSON } from 'date-fns'
import { Typography } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR'
import React, {
  // useMemo,
  useState
} from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'

// import { formatMonetaryValue } from '../../../domain/formatter'
// import { getLoadingSimplifiedLancamento, SimplifiedLancamento } from '../../../domain/lancamento/lancamento'
// import { isExpense, TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
// import { useTransactionContext } from '../../../hooks/contexts/transactionContext'
import { setEndOfDay, setStartOfDay } from '../../../hooks/useDate'
import {
  useLocalStorage,
  useNumericLocalStorage,
  useObjectLocalStorage
} from '../../../hooks/useLocalStorage'
// import { useFamilyCategorias } from '../../../queries/categoria/queries'
import { useToggleFamilyAutoCategorization } from '../../../queries/family/mutations'
import { useFamilyQuery } from '../../../queries/family/queries'
import { useDeleteLancamentos } from '../../../queries/lancamento/mutations'
import { useFamilyLancamentos, UseFamilyLancamentosOptions } from '../../../queries/lancamento/queries'
// import { useFamilyMeiosPagamentos } from '../../../queries/meioPagamento/queries'
// import { useFamilyOrcamentos } from '../../../queries/orcamento/queries'
import { noticeError } from '../../../services/Monitoring'
import Button from '../../Buttons'
// import { ModalDeleteTransaction } from '../../Modal/DeleteTransaction'
import { useTableSelection } from '../hooks'
// import Table from '../Table'
import { LancamentoEdit, useColumns } from './columnsV2'
import { ConfirmCategorization } from './ConfirmCategorization'
// import Footer from './Footer'
import * as S from './styles'
import { Props, Sort } from './types'

export const LancamentosTableV2 = ({ familyId }: Props) => {
  const { startDate, endDate } = useCoreContext()
  // const { setEditingEntry } = useTransactionContext()
  const { setValue } = useObjectLocalStorage<LancamentoEdit>('table_l_edits', {})
  const resetBeingEdited = useCallback(() => setValue({}), [setValue])
  // const { meiosPagamentos: meios } = useFamilyMeiosPagamentos(familyId)
  // const { categorias } = useFamilyCategorias(familyId)
  // const { orcamentos } = useFamilyOrcamentos(familyId)
  // const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [page, setPage] = useState(0)
  const {
    value: pageSize
    // setValue: setPageSize
  } = useNumericLocalStorage('lanc-page-size', 10)
  const [
    sort
    // setSort
  ] = useState<Sort>()
  const {
    // setValue: setStorageFilter,
    value: storageFilter
  } = useLocalStorage('filter')
  const {
    // setValue: setStorageMethodId,
    value: storageMethodId
  } = useLocalStorage('methodId')
  const {
    // setValue: setStorageBudgetId,
    value: storageBudgetId
  } = useLocalStorage('budgetId')
  const {
    // setValue: setStorageType,
    value: storageType
  } = useLocalStorage('type')
  const {
    // setValue: setStorageCategory,
    value: storageCategory
  } = useLocalStorage('category')
  const [filter, setFilter] = useState<string | undefined>(storageFilter || undefined)
  const [methodId, setPaymentMethodId] = useState<number | undefined>(Number(storageMethodId) || undefined)
  const [budgetId, setBudgetId] = useState<number | undefined>(Number(storageBudgetId) || undefined)
  const [type, setType] = useState<string | undefined>(storageType || undefined)
  const [categoryId, setCategoryId] = useState<number | undefined>(Number(storageCategory) || undefined)
  const start = setStartOfDay(new Date(startDate))
  const end = setEndOfDay(new Date(endDate))

  useEffect(() => {
    setFilter(storageFilter || undefined)
    setPaymentMethodId(Number(storageMethodId) || undefined)
    setBudgetId(Number(storageBudgetId) || undefined)
    setType(storageType || undefined)
    setCategoryId(Number(storageCategory) || undefined)
  }, [
    filter,
    setFilter,
    setPaymentMethodId,
    setBudgetId,
    setType,
    setCategoryId,
    storageFilter,
    storageMethodId,
    storageBudgetId,
    storageType,
    storageCategory
  ])

  const query: UseFamilyLancamentosOptions = {
    familyId,
    start,
    end,
    filter,
    page,
    size: pageSize,
    sort: sort?.prop,
    desc: sort?.desc,
    methodId,
    budgetId,
    categoryId,
    type
  }
  const {
    lancamentos,
    // totalPages,
    isLancamentosError,
    // isLoadingLancamentos,
    lancamentosError,
    refetchLancamentos
  } = useFamilyLancamentos(query, { enabled: familyId != null })
  const rows = lancamentos?.results || []

  const {
    selectedRows,
    // select, selectAll, unselect,
    unselectAll
  } = useTableSelection(rows)

  const {
    // isDeletingLancamentos,
    isLancamentosDeletionError,
    lancamentosDeletionError,
    resetLancamentosDeletion
  } = useDeleteLancamentos()

  const { family } = useFamilyQuery(familyId)
  const autoCategorizationNeverEnabled = family?.autoCategorizationDate === null
  const [confirmFirstCheck, setConfirmFirstCheck] = useState(false)
  const [confirmDisable, setConfirmDisable] = useState(false)
  const { toggleFamilyAutoCategorization } = useToggleFamilyAutoCategorization()
  // const onDelete = useCallback(async () => setModalConfirmDelete(true), [])

  const { setValue: setValueOnOrcamentoCategorization, value: valueOnOrcamentoCategorization } =
    useLocalStorage('onselect-orcamento-categorization')

  const onSelectOrcamento = useCallback(() => {
    if (autoCategorizationNeverEnabled && valueOnOrcamentoCategorization !== 'true') {
      setValueOnOrcamentoCategorization('true')
      setConfirmFirstCheck(true)
    }
  }, [autoCategorizationNeverEnabled, setValueOnOrcamentoCategorization, valueOnOrcamentoCategorization])

  const columns = useColumns(selectedRows, unselectAll, onSelectOrcamento)
  // const isLoading = isLoadingLancamentos || isDeletingLancamentos
  // const loadingData = useMemo(getLoadingSimplifiedLancamento, [])

  // const onSort = useCallback(
  //   (columnId: string, order: 'desc' | 'asc' | 'none') => {
  //     if (order === 'none') setSort({ desc: true, prop: 'Data' })
  //     else setSort({ desc: order === 'desc', prop: columnId })
  //   },
  //   [setSort]
  // )

  // const transformDownloadContent = (rows: SimplifiedLancamento[]) =>
  //   rows.map(
  //     ({
  //       data,
  //       descricao,
  //       valor,
  //       valorParcela,
  //       parcelado,
  //       parcelas,
  //       orcamentoId,
  //       categoriaId,
  //       meioPagamentoId,
  //       tipo
  //     }) => {
  //       const isParceled = parcelado && parcelas && valorParcela
  //       const amount: number = isParceled ? (valorParcela ?? 0) : (valor ?? 0)
  //       const finalAmount = isExpense(tipo) ? -amount : amount

  //       const dataTable = {
  //         Data: new Date(data).toLocaleDateString('pt-BR'),
  //         Descrição: descricao,
  //         Valor: formatMonetaryValue(finalAmount, true),
  //         Orçamento: orcamentos?.find(({ id }) => id === orcamentoId)?.name || 'Sem orçamento',
  //         Categoria:
  //           (categorias?.length && categorias?.find(({ id }) => id === categoriaId)?.nome) || 'Sem categoria',
  //         'Meio de pagamento':
  //           meios?.find(({ id }) => id === meioPagamentoId)?.nome || 'Sem meio de pagamento'
  //       }

  //       if (isParceled) {
  //         dataTable['Parcelado'] = 'Sim'
  //       }

  //       if (tipo) {
  //         dataTable['Tipo'] = TipoOrcamento[tipo]
  //       }

  //       return dataTable
  //     }
  //   )

  const handleCloseFirstCheck = () => {
    setConfirmFirstCheck(false)
    setTimeout(() => window.location.reload(), 200)
  }

  const handleCloseDisable = () => {
    setConfirmDisable(false)
  }

  useEffect(() => unselectAll(), [unselectAll, pageSize, page])
  useEffect(() => setPage(0), [pageSize])
  useEffect(resetBeingEdited, [page, resetBeingEdited])

  const table = useMaterialReactTable({
    data: rows,
    columns,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    // enableRowActions: true,
    enableRowSelection: true,
    initialState: {
      // showColumnFilters: true,
      // showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions']
      },
      density: 'compact'
    },
    enableDensityToggle: false,
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 0.5rem' }}>
          <Typography variant="subtitle2">Lançamentos</Typography>
        </div>
      )
    },
    muiTablePaperProps: {
      sx: {
        margin: 0
      }
    },
    muiTopToolbarProps: {
      sx: {
        '& .MuiBox-root': {
          alignItems: 'center',
          background: '#e4eaf4'
        }
      }
    },
    localization: MRT_Localization_PT_BR
  })

  // TODO: improve error handling
  if (isLancamentosError || isLancamentosDeletionError) {
    const error = lancamentosError || lancamentosDeletionError

    noticeError(error, { familyId, start, end })

    return (
      <S.ErrorContainer>
        <br />
        Erro ao carregar tabela :c <br />
        Tente novamente: <br />
        <Button
          text="Tentar novamente."
          onClick={() => {
            refetchLancamentos()
            resetLancamentosDeletion()
          }}
        />
      </S.ErrorContainer>
    )
  }

  // const editLancamento = (lancamento: SimplifiedLancamento) => {
  //   const copy: SimplifiedLancamento & { tipoOrcamento?: TipoOrcamento } = { ...lancamento }
  //   copy.data = format(parseJSON(lancamento.data), 'dd/MM/yyyy')
  //   copy.tipoOrcamento = lancamento.tipo
  //   copy.tipoDeParcela = (copy?.parcelas ?? 0) > 1 ? copy?.tipoDeParcela : 99

  //   // @ts-expect-error
  //   setEditingEntry(copy)
  // }

  return (
    <>
      <ConfirmCategorization
        handleCloseFirstCheck={handleCloseFirstCheck}
        handleCloseDisable={handleCloseDisable}
        confirmFirstCheck={confirmFirstCheck}
        setConfirmFirstCheck={setConfirmFirstCheck}
        confirmDisable={confirmDisable}
        setConfirmDisable={setConfirmDisable}
        toggleFamilyAutoCategorization={toggleFamilyAutoCategorization}
        familyId={familyId}
      />

      <MaterialReactTable table={table} />
    </>
  )
}
