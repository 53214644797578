import { Button, ButtonProps, styled } from '@mui/material'

const StyledButton = styled(Button)(({ theme, color }) => ({
  borderRadius: '1rem',
  textTransform: 'none',
  display: 'flex',
  gap: '0.35rem',

  '&.MuiButton-outlined': {
    borderColor: color && theme.palette[color].main,
    color: color && theme.palette[color].main
  },

  '&.MuiButton-contained': {
    backgroundColor: color && theme.palette[color].main,
    borderRadius: '1rem'
  },

  '&.text': {
    color: color && theme.palette[color].main
  },

  '&.MuiButton-containedSizeSmall': {
    svg: {
      fontSize: '1rem'
    }
  },

  '@media screen and (min-width: 1024px)': {
    minWidth: '100px'
  }
}))

export const ButtonDefault = ({ children, color = 'secondary', ...props }: ButtonProps) => {
  return (
    <StyledButton component="button" variant="outlined" color={color} {...props}>
      {children}
    </StyledButton>
  )
}
