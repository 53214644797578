import { postWithToken } from '../../../webClient'
import { ManyResults } from '../../Results'
import { Pessoa } from '../Pessoa'

interface Params {
  familyId: number
}

export const getAllPessoasByFamily = async ({ familyId }: Params) => {
  const url = `pessoa/familia/${familyId}/filtro`
  const { data: { results: pessoas, success, errorMsg } = {}, status } = await postWithToken<
    ManyResults<Pessoa>,
    object
  >(url, {})
  if (!success || status !== 200) {
    const msg = `Error getting pessoas from family ${familyId}: ${errorMsg}`
    console.error(msg)
    throw new Error(msg)
  }
  return pessoas || []
}
