import React, { useCallback, useEffect } from 'react'

import { Connection } from '../../../domain/bankingIntegration/Connection'
import { asyncDelay } from '../../../domain/timing'
import { useCurrentFamilyId } from '../../../hooks/useCurrentFamilyId'
import { useDeleteConnectionMutation } from '../../../queries/bankingIntegration/mutations'
import { useBankIntegrationConnections } from '../../../queries/bankingIntegration/queries'
import { useCurrentFamilyPessoasQuery } from '../../../queries/pessoa/queries'
import Table from '../Table'
import { useColumns } from './columns'
import ExpandedConnection from './ExpandedConnection'

export interface TableData {
  connection: Connection
  responsible?: string
}

interface Props {
  onConnectionDeletion?: (isDeleting: boolean, isError: boolean, error: Error | null) => void
}

export default ({ onConnectionDeletion }: Props) => {
  const { familyId } = useCurrentFamilyId()
  const { connections, refetchConnections } = useBankIntegrationConnections(familyId)
  const { deleteConnection, isDeletingConnection, isConnectionDeletionError, connectionDeletionError } =
    useDeleteConnectionMutation()

  const { pessoas } = useCurrentFamilyPessoasQuery(familyId)

  useEffect(() => {
    onConnectionDeletion?.(isDeletingConnection, isConnectionDeletionError, connectionDeletionError)
  }, [isDeletingConnection, isConnectionDeletionError, connectionDeletionError, onConnectionDeletion])

  const onConnectionDelete = useCallback(
    async (itemId: string) => {
      await deleteConnection({ itemId })
      await asyncDelay(750)
      await refetchConnections()
    },
    [deleteConnection, refetchConnections]
  )
  const columns = useColumns(onConnectionDelete)
  const tableData: TableData[] = connections.map((connection) => ({
    connection,
    responsible: pessoas.find(({ usuario }) => connection.userId === usuario?.id)?.nome || ''
  }))

  const transformDownloadContent = (rows: TableData[]) =>
    rows.map(({ connection, responsible }) => ({
      Conexão: connection.conexaoNome,
      'Contas Conectadas': connection.qtdContasCards,
      Status: connection.status,
      Responsável: responsible,
      'Última Atualização': connection.ultimoSucesso?.toLocaleDateString('pt-BR')
    }))

  return (
    <Table
      title="Conexões"
      columns={columns}
      data={tableData || []}
      downloadFileName="Conexões"
      mapDownloadFile={transformDownloadContent}
      downloadFormat="xlsx"
      renderExpanded={({ connection }) => <ExpandedConnection key={connection.id} connection={connection} />}
      widthBeforeHorizontalScroll={1200}
    />
  )
}
