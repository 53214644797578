import React from 'react'

import { SelectAutoComplete } from '../../Mui/SelectAutoComplete'

interface Props {
  selected?: number
  onChange?: (year: number) => void
}

export const YearSelect = ({ selected, onChange }: Props) => {
  const options: { label: string; value: number }[] = []
  const currentYear = new Date().getFullYear()
  const yearsRange = 5
  for (let year = currentYear; year >= currentYear - yearsRange; year--)
    options.push({ label: year.toString(), value: year })
  const placeholder = options.find(({ value }) => value === selected)?.label || currentYear.toString()
  if (!selected && onChange) onChange(currentYear)

  return (
    <SelectAutoComplete<{ label: string; value: number }>
      options={options}
      placeholder={placeholder}
      onChange={(option) => {
        if (option) onChange?.(Number(option.value))
      }}
    />
  )
}
