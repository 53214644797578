import { InputMask } from '@react-input/mask'
import React, { useCallback } from 'react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'

import Colors from '../../domain/Colors'

const FormStyleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: flex-start;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 5px 8px;
  color: ${Colors.disabledTextGray};
  background-color: ${Colors.disabledTextBackground};
  border-radius: 1.3rem;
  border: 0px solid white;
  font-size: 0.875rem;

  & input {
    background-color: ${Colors.disabledTextBackground};
    color: ${Colors.disabledTextGray};
    border-bottom: 0px !important;
  }
`

interface Props {
  currentDate?: Date
  onChange?: (date: Date | undefined) => void
  yearsRange?: number
  formStyle?: boolean
}

export default ({ currentDate, onChange, yearsRange, formStyle }: Props) => {
  const Picker = useCallback(
    () => (
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={currentDate}
        dateFormatCalendar="MMMM"
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={yearsRange || 5}
        onChange={(date) => onChange?.(date === null ? undefined : date)}
        customInput={<InputMask mask="11/11/1111" />}
      />
    ),
    [currentDate, onChange, yearsRange]
  )

  if (!formStyle) return <Picker />
  return (
    <FormStyleContainer>
      <Picker />
    </FormStyleContainer>
  )
}
