import {
  createTheme as createThemeLegacy,
  MuiThemeProvider as LegacyMuiThemeProvider
} from '@material-ui/core/styles'
import { createTheme, ThemeProvider as MuiThemeProvider, TypographyVariants } from '@mui/material/styles'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    featured: React.CSSProperties
    featuredMedium: React.CSSProperties
    title: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    featured?: React.CSSProperties
    featuredMedium?: React.CSSProperties
    title?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    featured: true
    featuredMedium: true
    title: true
  }
}

const TYPOGRAPHY_VARIANTS = {
  fontFamily: '"Open Sans", sans-serif',
  fontSize: 14,
  h1: {
    fontSize: '2.57rem',
    fontWeight: 700,
    lineHeight: 1.22
  },
  h2: {
    fontSize: '2.28rem',
    fontWeight: 700,
    lineHeight: 1.22
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 1.22
  },
  h4: {
    fontSize: '1.7rem',
    fontWeight: 700,
    lineHeight: 1.22
  },
  h5: {
    fontSize: '1.4rem',
    fontWeight: 700,
    lineHeight: 1.22
  },
  h6: {
    fontSize: '1.2rem',
    fontWeight: 700,
    lineHeight: 1.22
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.22
  },
  featured: {
    fontSize: '1.2rem',
    fontWeight: 700,
    lineHeight: 1.22,
    textTransform: 'uppercase'
  },
  featuredMedium: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.22,
    textTransform: 'uppercase'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.22
  }
}

export const CurrentMuiThemeProvider = ({ children, theme }) => {
  const { colors } = theme || {}

  const themeMui = createTheme({
    cssVariables: true,
    palette: {
      primary: {
        main: colors.primary
      },
      secondary: {
        main: colors.secondary
      }
    },
    typography: TYPOGRAPHY_VARIANTS as TypographyVariants,
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              borderRadius: 15
            }
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: '#f8f9fe',
              '& > td': {
                backgroundColor: '#f8f9fe !important'
              }
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'unset',
            fontSize: 'unset',
            padding: '0'
          },
          footer: {
            fontWeight: 700,
            borderRadius: '0 0 15px 15px',
            padding: '16px 10px'
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          regular: {
            backgroundColor: '#e4eaf4',
            borderRadius: '15px 15px 0 0',
            '&:last-child': {
              borderRadius: 0
            }
          }
        }
      }
    }
  })

  const GlobalStyle = createGlobalStyle`
    && {
      #menu-appbar {
        z-index: 999;
      }

      .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-label {
        text-transform: capitalize;
      }
      .MuiPickersYear-yearButton.Mui-selected {
        background-color: ${themeMui?.palette?.secondary.main};
        color: ${themeMui?.palette?.getContrastText(themeMui?.palette?.secondary.main)};
      }
      .MuiDialogActions-root .MuiButton-text.MuiButton-textPrimary {
        color: ${themeMui?.palette?.primary.main};
        text-transform: none;
      }
      .MuiYearCalendar-root .MuiPickersYear-yearButton.Mui-selected {
        background-color: ${themeMui?.palette?.secondary.main} !important;
        color: ${themeMui?.palette?.getContrastText(themeMui?.palette?.secondary.main)} !important;
      }

      .MuiButtonBase-root.MuiPickersDay-root:hover {
        background-color: ${themeMui?.palette?.secondary.light};
        color: ${themeMui?.palette?.getContrastText(themeMui?.palette?.secondary.light)};
      }
      .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
        background-color: ${themeMui?.palette?.secondary.main};
        color: ${themeMui?.palette?.getContrastText(themeMui?.palette?.secondary.main)};
      }
      
      .MuiButtonBase-root.MuiPickersDay-root.Mui-selected:focus,
      .MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover {
        background-color: ${themeMui?.palette?.secondary.light} !important;
        color: ${themeMui?.palette?.getContrastText(themeMui?.palette?.secondary.light)} !important;
        border-radius: 50% !important;
      }
      .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
        border-color: ${themeMui?.palette?.secondary.light};
        color: ${themeMui?.palette?.secondary.main};
      }
      .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today.Mui-selected {
        color: ${themeMui?.palette?.getContrastText(themeMui?.palette?.secondary.main)};
      }

      /**
         @description: Necessário para manter a convivencia das diferentes versoes do material-ui
      */
      .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionSticky {
        background: white !important;
        border-bottom: 1px solid #e0e0e0  !important;
        margin: 0  !important;
        box-shadow: none  !important;
        z-index: 998  !important;
        height: 70px  !important;
        box-shadow: none  !important;
        border-radius: 0 !important;
      }

      .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorSecondary.MuiButton-root.MuiButton-outlined {
        border: 1px solid ${themeMui?.palette?.secondary.main} !important;
        color: ${themeMui?.palette?.secondary.main} !important;
        padding: 5px 15px !important;
        border-radius: 15px !important;
        font-size: 0.85rem;
      }
      
      .MuiPickersLayout-shortcuts .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorSecondary.MuiButton-root.MuiButton-outlined {
        font-size: 0.75rem !important;
      }

      .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
        border-radius: 50% !important;
      }

      .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
        display: flex !important;
        padding-bottom: 6px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        padding-top: 6px !important;
      }

      .MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary {
        display: inline-flex !important;
        padding: 9px !important;
        border-radius: 50% !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        z-index: 1 !important;
      }

      .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-colorSecondary,
      .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorSecondary {
        background-color: ${themeMui?.palette?.secondary.main} !important;
        color: ${themeMui?.palette?.getContrastText(themeMui?.palette?.secondary.main)} !important;
        padding: 0.2rem 1rem !important;
      }
      .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-colorSecondary{
        font-size: 0.75rem !important;
      }
  
      .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSecondary.MuiButton-sizeMedium.MuiButton-root.MuiButton-text,
      .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSecondary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorSecondary.MuiButton-root.MuiButton-text {
        color: ${themeMui?.palette?.secondary.main} !important;
  
        &:hover {
          .MuiTouchRipple-root {
            --variant-containedBg: var(--mui-palette-secondary-dark) !important;
            --variant-textBg: rgba(var(--mui-palette-secondary-mainChannel) / var(--mui-palette-action-hoverOpacity)) !important;
            --variant-outlinedBorder: var(--mui-palette-secondary-main) !important;
            --variant-outlinedBg: rgba(var(--mui-palette-secondary-mainChannel) / var(--mui-palette-action-hoverOpacity)) !important;
          }
        }
      }
    }
  `

  return (
    <MuiThemeProvider theme={themeMui}>
      <ThemeProvider
        theme={{
          ...theme,
          colors: {
            primary: themeMui.palette.primary.main,
            secondary: themeMui.palette.secondary.main,
            primaryDarker: themeMui.palette.primary.dark,
            primaryLighter: themeMui.palette.primary.light,
            secondaryDarker: themeMui.palette.secondary.dark,
            secondaryLighter: themeMui.palette.secondary.light
          }
        }}
      >
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export const LegacyMuiProvider = ({ children, theme }) => {
  const themeLegacy = createThemeLegacy({
    palette: {
      primary: {
        main: theme.colors.primary
      },
      secondary: {
        main: theme.colors.secondary
      }
    },
    typography: TYPOGRAPHY_VARIANTS,
    overrides: {
      MuiTableRow: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#f8f9fe'
          },
          cursor: 'pointer'
        }
      },
      // @ts-ignore
      MUIDataTableSelectCell: {
        fixedHeaderCommon: {
          backgroundColor: 'unset'
        }
      },
      MuiTableCell: {
        root: {
          fontFamily: 'unset',
          borderTop: 'none',
          padding: '1rem 10px'
        },
        footer: {
          color: '#FFF',
          fontWeight: 700,
          padding: '1rem 10px'
        }
      },
      MuiTableFooter: {
        root: {
          borderRadius: '0 0 15px 15px'
        }
      },
      MUIDataTableHeadCell: {
        fixedHeaderYAxis: {
          fontWeight: 700
        }
      },
      MuiPaper: {
        root: {
          overflow: 'hidden',
          margin: 0
        },
        elevation4: {
          borderRadius: 15
        }
      },
      MuiToolbar: {
        regular: {
          // backgroundColor: '#e4eaf4',
          borderRadius: '15px 15px 0 0',
          '&:last-child': {
            borderRadius: 0
          }
        }
      },
      MUIDataTablePagination: {
        root: {
          '&:last-child': {
            margin: '0px 24px 0px 24px',
            padding: 0
          }
        }
      }
    }
  })

  return <LegacyMuiThemeProvider theme={themeLegacy}>{children}</LegacyMuiThemeProvider>
}
