import { noticeError } from '../../../services/Monitoring'
import { extratosApi } from '../../../webClient'

export interface SendToExtratoBody {
  file: string
  type: string
  bank: string
  paymentMethod?: string
  empresaId: string
  familiaId: string
  meioPagamentoId: number
  typePdf?: string
  descriptionYear?: number
}

export interface SentExtratoResponse {
  info: {
    success: boolean
    code: number
    message: string
  }
  result: Result
}

export interface Result {
  singleResult: unknown
  hashResults: unknown
  results: unknown
  affectedResults: number
  totalResults: number
  currentPage: number
  currentPageTotal: number
  hasNext: boolean
  hasPrevious: boolean
  success: boolean
  exception: DuplicatedLancamentos[]
  query: unknown
  errorMsg: string
  errorCode: unknown
}

export interface DuplicatedLancamentos {
  id: number
  date: string
  value: number
  description: string
}

export const sendExtratoApi = async (body: SendToExtratoBody) => {
  // const { status, data: response } = await extratosApi.post<SentExtratoResponse>('/extrato', body)
  const { status, data: response } = await extratosApi.post<SentExtratoResponse>('/v2/extrato', body)

  if (status !== 200) {
    const { familiaId, empresaId, meioPagamentoId, paymentMethod, typePdf, descriptionYear } = body
    const err = new Error('Erro ao enviar extratos.')
    noticeError(err, {
      empresaId,
      meioPagamentoId,
      familiaId,
      paymentMethod,
      typePdf,
      descriptionYear
    })
    throw err
  }

  return response.result
}
