import { QueryObserverResult, useQuery } from 'react-query'

import { fetchPluggyToken } from '../../domain/bankingIntegration/api/fetchPluggyToken'
import { getConnectionsAndAccounts } from '../../domain/bankingIntegration/api/getConnectionsAndAccounts'
import { Connection } from '../../domain/bankingIntegration/Connection'
import { QueryOptions } from '../queryOptions'

export interface UseBankIntegrationConnectionsResponse {
  isLoadingConnections: boolean
  isErrorConnections: boolean
  connections: Connection[]
  connectionsError: Error | null
  refetchConnections: () => Promise<QueryObserverResult<Connection[], Error>>
}

export const cacheBaseKey = ['bank-integration']
export const accountCacheKey = [...cacheBaseKey, 'accounts']

export const useBankIntegrationConnections = (
  familyId: number,
  options?: QueryOptions<Connection[]>
): UseBankIntegrationConnectionsResponse => {
  const {
    isLoading: isLoadingConnections,
    isError: isErrorConnections,
    data: connections = [],
    error: connectionsError,
    refetch: refetchConnections
  } = useQuery<unknown, Error, Connection[]>(
    [...accountCacheKey, familyId],
    () => getConnectionsAndAccounts(familyId),
    options
  )
  return {
    isLoadingConnections,
    isErrorConnections,
    connections: connections.sort(({ ultimoSucesso: date1 }, { ultimoSucesso: date2 }) =>
      (date1 || '') > (date2 || '') ? -1 : 1
    ),
    connectionsError,
    refetchConnections
  }
}

export const usePluggyToken = (userId: number, itemId?: string, options?: QueryOptions<string>) => {
  const {
    isLoading: isLoadingToken,
    isError: isErrorToken,
    data: token,
    error: tokenError,
    isRefetching: isRefetchingToken
  } = useQuery<unknown, Error, string>(
    [cacheBaseKey, 'token', userId, itemId],
    () => fetchPluggyToken(userId, itemId),
    options
  )

  return {
    isLoadingToken,
    isErrorToken,
    token,
    tokenError,
    isRefetchingToken
  }
}
