import { useQuery } from 'react-query'

import {
  fetchSimplifiedByFamily,
  FetchSimplifiedResponse,
  getFamiliesApi
} from '../../domain/lancamento/api/fetchByFamily'
import { QueryOptions } from '../queryOptions'

export const cacheBaseKey = 'lancamentos'

export interface UseFamilyLancamentosOptions {
  familyId: number
  start: Date
  end: Date
  filter?: string
  page: number
  size: number
  sort?: string
  desc?: boolean
  methodId?: number
  budgetId?: number
  categoryId?: number
  type?: string
  pluggyInvestmentId?: string
}

export const useFamilyLancamentos = (
  {
    familyId,
    start,
    end,
    filter,
    page,
    size,
    sort,
    desc,
    methodId: meioPagamentoId,
    budgetId,
    categoryId,
    type,
    pluggyInvestmentId
  }: UseFamilyLancamentosOptions,
  options?: QueryOptions<FetchSimplifiedResponse>
) => {
  const {
    data: lancamentos,
    isError: isLancamentosError,
    isLoading: isLoadingLancamentos,
    error: lancamentosError,
    refetch: refetchLancamentos
  } = useQuery<unknown, Error, FetchSimplifiedResponse>(
    [
      cacheBaseKey,
      familyId,
      page,
      size,
      filter,
      start,
      end,
      sort,
      desc,
      meioPagamentoId,
      budgetId,
      categoryId,
      type,
      pluggyInvestmentId
    ],
    () =>
      fetchSimplifiedByFamily(
        familyId,
        start,
        end,
        filter,
        page,
        size,
        sort,
        desc,
        meioPagamentoId,
        budgetId,
        categoryId,
        type,
        pluggyInvestmentId
      ),
    {
      ...options,
      retry: 2,
      retryDelay: 2000
    }
  )

  const totalPages = lancamentos ? Math.ceil(lancamentos.totalResults / lancamentos.pageSize) : 0

  return {
    lancamentos,
    totalPages,
    isLancamentosError,
    isLoadingLancamentos,
    lancamentosError,
    refetchLancamentos
  }
}

export const useGetFamiliesQuery = () => {
  const {
    data: families,
    error: getFamiliesError,
    isLoading: getFamiliesIsLoading,
    isError: getFamiliesIsError,
    refetch: refetchGetFamilies
  } = useQuery('familias', () => getFamiliesApi())
  return {
    families,
    getFamiliesError,
    getFamiliesIsLoading,
    getFamiliesIsError,
    refetchGetFamilies
  }
}
